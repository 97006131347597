<template>
  <template v-if="!noSearch">
    <div class="search-header mb-8">
      <a-form layout="inline"
              :model="searchForm"
              :wrapperCol="{style: 'minWidth: 160px'}">
        <a-form-item label="客户名称">
          <a-input v-model:value="searchForm.name"
                   placeholder="请输入客户名称"
                   style="width: 160px"
                   v-debounce="onSearchSubmit" />
        </a-form-item>

        <a-form-item v-if="role !== roleEnum.STAFF"
                     class="cursor-pointer"
                     label="所属成员">
          <div class="ant-input-affix-wrapper flex justify-between mt-4"
               @click.stop="selectStaffModalVisible = true">
            <span v-if="staffList.length === 0"
                  style="color: #bfbfbf;">请选择所属员工</span>
            <div class="overflow-hidden"
                 style="max-width: 160px; max-height: 24px;">
              <template v-for="(staff, index) in staffList"
                        :key="index">
                <div v-is="'ww-open-data'"
                     class="ww-open-data"
                     type="userName"
                     :openid="staff"></div>
                <span v-if="index !== staffList.length - 1">, </span>
              </template>
            </div>

            <template v-if="staffNum > 0">等{{staffNum}}人</template>
          </div>
        </a-form-item>

        <a-form-item label="标签"
                     :wrapperCol="{style: 'minWidth: 200px'}">
          <a-select mode="multiple"
                    placeholder="请选择标签"
                    showArrow
                    :maxTagCount="2"
                    @change="handleTagChange">
            <a-select-opt-group v-for="enterprise in enterpriseTagList"
                                :key="enterprise.workWechatTagGroupId"
                                :label="enterprise.name">
              <a-select-option v-for="tag in enterprise.corpTagList"
                               :key="tag.workWechatThirdAppTagId">
                {{tag.name}}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>

        <a-form-item label="跟进状态">
          <a-select v-model:value="searchForm.stage"
                    placeholder="请选择跟进状态"
                    allowClear
                    @change="onSearchSubmit">
            <a-select-option v-for="item in customerStages"
                             :key="item.code">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="添加时间"
                     :wrapperCol="{style: 'width: 280px'}">
          <a-range-picker v-model:value="timeValue"
                          format="YYYY-MM-DD"
                          @change="handleSetectTime" />
        </a-form-item>

        <a-form-item label="流失状态">
          <a-select v-model:value="searchForm.isLost"
                    placeholder="请选择流失状态"
                    allowClear
                    @change="onSearchSubmit">
            <a-select-option v-for="(desc, status) in lostStatus"
                             :key="status">
              {{ desc }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <a-button class="self-end h-36"
                type="primary"
                style="width: 146px"
                :loading="lodash.includes(['pending', 'downloading'], process.processStatus)"
                @click="handleExport">
        {{ exprotStatus }}
      </a-button>
    </div>

    <div v-if="role === roleEnum.ADMIN"
         class="sync-button">
      <a-button type="link"
                :disabled="isSync"
                @click="handleSyncButton">
        <SyncOutlined :spin="isSync" />同步
      </a-button>
      <span class="content">{{ syncTime ? `最后同步时间：${syncTime}` : '未同步' }}</span>
    </div>
  </template>

  <a-table :columns="columns"
           :data-source="customers"
           :scroll="{ x: '100%', y: '100vh' }"
           :pagination="pagination"
           row-key="id"
           @change="onPaginationChange"
           :loading="tableLoading">
    <template #realName="{ record }">
      <avatar-column :avatar="{avatar: record.avatar, name: record.realName || record.wechatName, corpName: record.customerCorpName}"
                     :userType="record.userType">
        <template #bottom>
          <div class="flex mt-4">
            <a-tag v-if="record.isLost"
                   class="py-0 px-4 m-0 mr-4"
                   color="error">已流失</a-tag>
            <span class="line-clamp-1 break-all">昵称：{{record.wechatName}}</span>
          </div>
        </template>
      </avatar-column>
    </template>

    <template #staffWorkWechatThirdAppUserId="{ text }">
      <div v-is="'ww-open-data'"
           class="ww-open-data"
           type="userName"
           :openid="text"></div>
    </template>

    <template #tags="{ record }">
      <a-tag v-for="(tag, index) in record.tags"
             :key="index">{{ tag.name }}</a-tag>
    </template>

    <template #createdTime="{ text }">
      {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
    </template>

    <template #stage="{ text }">
      {{ getCustomerStageName(text) }}
    </template>

    <template #action="{ record }">
      <router-link :to="{path:'/customer/customerDetail',query:{ customerId: record.customerId, id: record.id}}">详情
      </router-link>
    </template>
  </a-table>

  <select-staff-modal v-model:visible="selectStaffModalVisible"
                      :defaultCheckedKeys="checkedKeys"
                      @saveStaff="saveStaff">
  </select-staff-modal>
</template>

<script>
import _ from "lodash";
import { defineComponent, onUnmounted, reactive, ref, watch } from "vue";
import { Select, DatePicker, message } from "ant-design-vue";
import { apiStore } from "@/shared/service/api-client";

import customerApi from "@/service/api/customer";
import customerStateApi from "@/service/api/CustomerStateApi";
import corpTagApi from "@/service/api/corpLag.js";
import { roleEnum, formatTimeForSearch, getWxAgentConfig } from "@/global";

import selectStaffModal from "@/components/SelectStaffByGroup.vue";

import { useRouter } from "vue-router";
import AvatarColumn from "@/components/AvatarColumn";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

export default defineComponent({
  name: "CustomerIndex",

  props: {
    noSearch: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
  },

  components: {
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    selectStaffModal,
    AvatarColumn,
  },

  setup(props) {
    // 搜索
    const timeValue = ref([]);
    const searchForm = reactive({
      name: "",
      startTime: undefined,
      endTime: undefined,
      ThirdApptagIds: "",
      corpStaffId: "",
      stage: undefined,
      isLost: undefined,
    });

    watch(
      () => props.form,
      (value) => {
        const trulyValues = _.omitBy(value, _.isUndefined);
        _.assign(searchForm, trulyValues);
      },
      { deep: true, immediate: true }
    );

    const lostStatus = {
      0: "未流失",
      1: "已流失",
    };

    const handleTagChange = (value, option) => {
      console.log(option);
      searchForm.ThirdApptagIds = _.map(option, (tag) => tag.key).join(",");
      onSearchSubmit();
    };

    const handleSetectTime = (res) => {
      const time = formatTimeForSearch(res);
      _.assign(searchForm, time);
      onSearchSubmit();
    };

    // 标签
    const enterpriseTagList = ref([]);

    const getTag = async () => {
      enterpriseTagList.value = await corpTagApi.getList();
    };

    // 跟进状态
    const customerStages = ref([]);
    const getCustomerStages = async () => {
      customerStages.value = await customerStateApi.findAllStages({
        withNoneStage: true,
      });
    };
    const getCustomerStageName = (code) => {
      const stage = _.find(customerStages.value, function (stage) {
        return stage.code === code;
      });
      return stage?.name ?? " - ";
    };

    // 同步时间
    const isSync = ref(false);
    const syncTime = ref("");
    const getDataSyncTime = async () => {
      const lastSyncTimeObj = await customerApi.getLastSyncTime();

      syncTime.value = lastSyncTimeObj.lastSyncTime;
      if (lastSyncTimeObj.processStatus !== "finished") {
        isSync.value = true;
        setInter();
      }
    };

    const handleSyncButton = async () => {
      isSync.value = true;
      await customerApi.asyncAllCustomers();
      setInter();
    };

    const timer = ref();

    const setInter = () => {
      let i = 0;
      timer.value = setInterval(async () => {
        if (i++ >= 10) {
          clearInterval(timer.value);
          return;
        }

        const lastSyncTimeObj = await customerApi.getLastSyncTime();
        if (lastSyncTimeObj.processStatus !== "finished") {
          return;
        }

        isSync.value = false;
        syncTime.value = lastSyncTimeObj.lastSyncTime;
        message.success("同步成功");
        clearInterval(timer.value);
        await fetchPaginationData();
      }, 10000);
    };

    // 所属员工
    const selectStaffModalVisible = ref(false);
    const staffList = ref([]);
    const checkedKeys = ref([]);
    const staffNum = ref(0);

    const saveStaff = (checkedStaffs) => {
      checkedKeys.value = [];
      staffNum.value = checkedStaffs.length;

      selectStaffModalVisible.value = false;

      staffList.value = _.map(
        checkedStaffs,
        (staff) => staff.workWechatThirdAppUserId
      );
      checkedKeys.value = _.map(checkedStaffs, (staff) => staff.staffId);
      searchForm.corpStaffId = _.map(
        checkedStaffs,
        (staff) => _.split(staff.staffId, "_")[1]
      ).join(",");

      onSearchSubmit();
    };

    // 导出客户
    const exprotStatus = ref("导出");

    const process = reactive({
      processStatus: "unstart",
    });

    const initSyncGroup = async () => {
      const res = await customerApi.getLastExportStatus();
      _.assign(process, res);

      if (process.processStatus === "exported") {
        exprotStatus.value = "下载";
      }
    };

    const handleExport = () => {
      if (_.includes(["unstart", "downloaded"], process.processStatus)) {
        exportedViewerData();
        return;
      }

      if (process.processStatus === "exported") {
        downloadViewrData();
      }
    };

    const exportedViewerData = async () => {
      await customerApi.asyncExport(searchForm);
      exprotStatus.value = "导出中..";
      checkProcessStatus();
    };

    const exportTimer = ref();
    const checkProcessStatus = () => {
      let i = 0;
      exportTimer.value = setInterval(async () => {
        i++;
        if (i >= 60) {
          clearInterval(exportTimer.value);
        }
        const res = await customerApi.getLastExportStatus();
        _.assign(process, res);

        if (process.processStatus === "exported") {
          message.success("导出成功");
          exprotStatus.value = "下载";

          clearInterval(exportTimer.value);
        }

        if (process.processStatus === "downloaded") {
          message.success("下载成功");
          exprotStatus.value = "导出";

          clearInterval(exportTimer.value);
        }
      }, 500);
    };

    const downloadViewrData = async () => {
      let url =
        "https://" +
        document.domain +
        "/api/console/live/room/download?id=" +
        process.id +
        "&token=" +
        apiStore.token;

      window.location.href = url;
      checkProcessStatus();
    };

    // 表格
    const router = new useRouter();

    const columns = [
      {
        title: "客户",
        dataIndex: "realName",
        key: "realName",
        width: "22.5%",
        slots: { customRender: "realName" },
      },
      {
        title: "所属成员",
        dataIndex: "staffWorkWechatThirdAppUserId",
        key: "staffWorkWechatThirdAppUserId",
        slots: { customRender: "staffWorkWechatThirdAppUserId" },
      },
      {
        title: "标签",
        dataIndex: "tags",
        key: "tags",
        width: "20%",
        slots: { customRender: "tags" },
      },
      {
        title: "跟进状态",
        dataIndex: "stage",
        key: "stage",
        slots: { customRender: "stage" },
      },
      {
        title: "添加时间",
        dataIndex: "createdTime",
        key: "createdTime",
        slots: { customRender: "createdTime" },
      },
      {
        title: "添加渠道",
        dataIndex: "sourceContent",
        key: "sourceContent",
      },
      {
        title: "操作",
        key: "action",
        align: "center",
        slots: { customRender: "action" },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, customerApi.search);

    onUnmounted(() => {
      clearInterval(timer.value);
      clearInterval(exportTimer.value);

      timer.value = null;
      exportTimer.value = null;
    });

    getTag();
    getCustomerStages();
    getDataSyncTime();
    initSyncGroup();

    getWxAgentConfig();

    return {
      lodash: _,
      role: apiStore.user.role,
      roleEnum,

      searchForm,
      lostStatus,
      timeValue,
      handleTagChange,
      handleSetectTime,

      enterpriseTagList,
      getTag,

      customerStages,
      getCustomerStageName,

      selectStaffModalVisible,
      staffList,
      checkedKeys,
      staffNum,
      saveStaff,

      syncTime,
      isSync,
      handleSyncButton,

      exprotStatus,
      process,
      handleExport,

      columns,
      customers: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-input-affix-wrapper) {
  &::before {
    display: none;
  }
}
</style>