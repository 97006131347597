<template>
  <a-spin :spinning="spinning">
    <div class="member-detail">
      <router-header :routerHeaderInfo="routerHeaderInfo" />
      <a-row class="member-detail__banner">
        <a-col class="banner-left"
               :span="8">
          <div class="staff-name">
            <avatar-column :avatar="{avatar: staff.avatar, userId: staff.workWechatThirdAppUserId }"
                           :size="80"
                           is-user-id />
            <!-- <div class="flex items-center">
              <a-avatar v-if="staff?.avatar"
                        class="flex-shrink-0"
                        :size="80"
                        :src="staff.avatar"
                        alt="用户头像" />
              <svg-icon v-else
                        :size="80"
                        type="iconmorentouxiang" />

              <div v-is="'ww-open-data'"
                   class="ww-open-data ml-12"
                   style="max-width: 160px;"
                   type="userName"
                   :openid="staff.workWechatThirdAppUserId"></div>
            </div> -->
          </div>
        </a-col>
        <a-col class="banner-right"
               :span="15">
          <a-row>
            <a-col :span="8">
              <a-statistic title="客户总数">
                <template #formatter>
                  <count-to :endVal="Number(statictis.totalCnt)"
                            :duration="1000"></count-to>
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic title="今日新增(人)">
                <template #formatter>
                  <count-to :endVal="Number(statictis.todayCnt)"
                            :duration="1000"></count-to>
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic title="累计订单金额(元)">
                <template #formatter>
                  <count-to :endVal="Number(statictis.totalAmt / 100)"
                            :duration="1000"
                            :decimals="2"></count-to>
                </template>
              </a-statistic>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <div class="echart-data">
        <p class="title-mark pl16">客户管理阶段数据</p>
        <div class="Echarts">
          <div id="echarts"
               style="width: 100%;height:400px;"></div>
        </div>
      </div>
      <div>
        <a-tabs type="card"
                v-model:activeKey="activeKey">
          <a-tab-pane key="1"
                      tab="客户列表">
            <customer-index :no-search="true"
                            :form="{corpStaffId: $route.query.id}" />
          </a-tab-pane>
          <a-tab-pane key="2"
                      tab="订单列表"
                      force-render>
            <order-index no-search
                         :form="{staffId: $route.query.id}" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-spin>

</template>

<script>
import _ from "lodash";
import { CountTo } from "vue-count-to2";
import RouterHeader from "@/components/RouterHeader.vue";
import CustomerIndex from "@/views/customer/CustomerIndex";
import OrderIndex from "@/views/goods/OrderIndex";
import AvatarColumn from "@/components/AvatarColumn";

import { Statistic, Tabs } from "ant-design-vue";
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/chart/bar";
import "echarts/lib/component/grid";

import StaffApi from "@/service/api/staff";
import customerStateApi from "@/service/api/CustomerStateApi";
import { getWxAgentConfig } from "@/global";

export default {
  name: "MemberDetail",

  components: {
    RouterHeader,
    CustomerIndex,
    OrderIndex,
    AvatarColumn,

    AStatistic: Statistic,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    CountTo,
    // AAvatar: Avatar,
  },

  data() {
    const routerHeaderInfo = [
      {
        path: "/enterprise/memberManage",
        name: "成员管理",
      },
      { name: "成员详情" },
    ];
    const staff = {
      people: [],
    };
    const statictis = {
      todayCnt: 0,
      totalCnt: 0,
      totalAmt: 0,
    };
    const echartsData = {};
    return {
      spinning: true,
      statictis,
      routerHeaderInfo,
      echartsData,
      activeKey: "1",
      staff,
      chart: {},
      customerStages: [],
      getWxAgentConfig,
    };
  },

  beforeUnmount() {
    this.chart.dispose();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;
      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },

  async mounted() {
    this.getCustomerStages();
    this.getWxAgentConfig();
  },

  methods: {
    async getCustomerStages() {
      this.customerStages = await customerStateApi.findAllStages({
        withNoneStage: true,
      });

      this.initEchartsData();
      this.getStatictisValue();
    },

    initEchartsData() {
      this.echartsData = new Map();
      let data = [];
      this.customerStages.forEach((stage) => {
        data.push(stage.code);
      });
      _.forEach(data, (item) => {
        this.echartsData.set(item, 0);
      });
    },

    async getStatictisValue() {
      const res = await StaffApi.get(this.$route.query.id);

      const {
        avatar,
        name,
        roleCode,
        corpId,
        todayCnt,
        totalCnt,
        totalAmt,
        workWechatThirdAppUserId,
      } = res;

      _.assign(this.staff, {
        avatar,
        name,
        roleCode,
        corpId,
        workWechatThirdAppUserId,
      });

      _.assign(this.statictis, {
        todayCnt,
        totalCnt,
        totalAmt,
      });

      _.forOwn(res.stageMap, (value, key) => {
        this.echartsData.set(key, Number(value));
      });

      this.staff.people = [...this.echartsData.values()];
      this.drawLine();

      this.spinning = false;
    },

    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let indexChart = echarts.init(document.getElementById("echarts"));
      // 指定图表的配置项和数据
      let xData = [];
      this.customerStages.forEach((item) => {
        xData.push(item.name);
      });
      let option = {
        color: ["#31A1FF"],
        tooltip: {
          trigger: "axis",
        },
        title: {
          text: "总人数: " + this.statictis.totalCnt + "人",
        },
        xAxis: {
          data: xData,
        },
        yAxis: {},
        series: [
          {
            name: "人数",
            type: "bar",
            data: this.staff.people,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      indexChart.setOption(option);
      this.chart = indexChart;
    },
  },
};
</script>